
import { apiEndPoint } from "../../../../contants/Endpoints";
import { asyncLocalStorage } from "../../../../utils/asyncLocalStorage";
import { authAxios } from "../../../../utils/axiosWrapper";
import {
  ownerPutError,
  ownerPutRequest,
  ownerPutSuccess,
} from "../actions/actions";

export function putOwnerInfoSave(id, data, callback) {
  return (dispatch) => {
    dispatch(ownerPutRequest());
    authAxios
      // .put(apiEndPoint.CREATE_OWNER + id, data)
      .post(apiEndPoint.CREATE_OWNERV2, data)
      .then((response) => {
        dispatch(ownerPutSuccess(response.data));
        console.log("response: ", response.status);
        alert("Request Processed Successfully!")
        // asyncLocalStorage.setItem("contractId", response.data.contractId);
        if (response.status === 200) {
          callback();
        } else {
          window.alert("form already submitted!");
        }
      })
      .catch((error) => {
        dispatch(ownerPutError(error));
        alert("Operation Failed. Please, Try again.")
      });
  };
}
