// import {
//     animatedComponents,
//     colorStyles,
//   } from "../../../ToursMarchent/Utilities/SelectAnimation";
  import Select from "react-select";
import { animatedComponents, colorStyles } from "../../../ToursMarchent/Utilities/SelectAnimation";
import { CiLight } from "react-icons/ci";
import { useState } from "react";
import FileMultipleCategory from "../../../components/multipleimage/FileMultipleCategory";
import Loading from "../../../components/Loader/Loading";
import InputComponent from "../../../components/InputComponent";
import { apiEndPoint, baseUrl } from "../../../contants/Endpoints";
import { useEffect } from "react";
import { authAxios } from "../../../utils/axiosWrapper";
import { toastMessage } from "../../../utils/toast";
export default function AirportDestination () {
    const [urlTempStorageArray, setUrlTempStorageArray] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchString,setSearchString] = useState(" ")
    const [payloadIata, setPayload] = useState()
    const [minimumPrice, setMinimumPrice] = useState()


  const [airportList, setAirportList] = useState();
  useEffect(() => {
    authAxios
      .get(
        `${baseUrl}${apiEndPoint.GET_ALL_AIRPORT}pageNumber=1&pageSize=10&queryString=${searchString}&requestId=getairport`
      )
      .then((response) => setAirportList(response?.data?.entries));
  }, [searchString]);
  const transformedArray = airportList?.map(
    ({ iata_code, name }) => ({
      label: name,
      value: iata_code,
    })
  );
  console.log("payloadIata",payloadIata )

  const handleSubmit = () => {
   
    const payload = {
        iata_code: payloadIata?.value,
        minimum_price_from_dhaka: minimumPrice,
        thumb_image:urlTempStorageArray?.[0]?.url,
        
        requestId:"post airport destination",


    }

     authAxios
                  .post(`${apiEndPoint?.SET_AIRPORT_DESTINATION_IMAGE_ADD_WITH_PRICE}`, payload)
                  .then((res) => {
                 
                  
                    toastMessage("Airport price set successfully!");
                    setIsLoading(false);
                    window.location.reload()
                  })
                  .catch((err) => console.log(err));
    
  
   
    
  }
    return <div>
      <div className="max-w-[650px] mx-auto">
      <h2 className="text-[22px] underline text-btn-secondary text-center mt-[20px]">Airport Destination Add</h2>


        <div className="flex flex-col gap-[20px] pt-[30px]">
        <Select
              value={payloadIata?.iata_code}

              onChange={(selectedOption) => {
                // Handle the selected option
                setPayload(selectedOption); // Assuming iata_code is a property of the selected option
              }}
              onInputChange={(inputText) => {
                // Handle the input text value
                setSearchString(inputText); // Update the state with the input text
              }}
             
              components={animatedComponents}
              options={transformedArray}
              placeholder={"Select Airport "}
              styles={colorStyles}
              isSearchable={true}
            />

<InputComponent
                  inputValue={minimumPrice}
                  setInputValue={setMinimumPrice}
                  name="minimumPrice"
                  placeholder={"Enter Minimum Price"}
                  isReadOnly={false}
                  width={"w-0"}
                />

<div className="mx-[49px]">
              <div className="mt-4 mb-2">Upload Image:</div>
              {/* <RemoveableImageUploadViewCategory/> */}
              <div className="flex">
                <div className="mb-10  w-3/5">
                  <FileMultipleCategory
                    width={"w-full"}
                    id={1}
                    setNeedUpdate={() => {}}
                    urlTempStorageArray={urlTempStorageArray}
                    setUrlTempStorageArray={setUrlTempStorageArray}
                    multiple={false}
                    setIsLoading={setIsLoading}
                  />
                </div>
                {isLoading ? (
                  <div
                    className={`flex items-center justify-center  w-2/5 ${
                      urlTempStorageArray?.length ? "h-[245px]" : ""
                    }`}
                  >
                    <Loading />
                  </div>
                ) : (
                  <div className="w-2/5 ml-[20px]">
                    <img
                      className={`rounded-lg w-full ${
                        urlTempStorageArray?.length || urlTempStorageArray[0]?.url ? "h-[250px]" : ""
                      }`}
                      src={urlTempStorageArray[0]?.url || ""}
                      alt="Ghuddy"
                    />
                    <div className="text-txt-primary text-center mt-[10px] text-[12px]">
                      Image File:{" "}
                      {urlTempStorageArray?.length > 0
                        ? urlTempStorageArray[urlTempStorageArray?.length - 1]
                            .fileName
                        : `image`}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <button onClick={() => handleSubmit()} className="bg-btn-primary py-[10px] rounded-[12px] text-bg-white hover:bg-btn-secondary active:bg-btn-primary">Submit</button>

        </div>
      <div>

      </div>
      </div>
    </div>
}