import { useState } from "react";
import SingleSelectParent from "../../../components/singleSelect/SingleSelectParent";
import { useEffect } from "react";
import { noAuthAxios } from "../../../utils/axiosWrapperOpen";
import { apiEndPoint } from "../../../contants/Endpoints";
import Loading from "../../../components/Loader/Loading";
import FileMultipleCategory from "../../../components/multipleimage/FileMultipleCategory";
import { authAxios } from "../../../utils/axiosWrapper";
import { toastMessage } from "../../../utils/toast";

export default function CountryThumbUmage () {
     const [countryCode, setCountryCode] = useState("");
     const [name, setName] = useState("");
     const [allCountries, setAllCountries] = useState();
     const [urlTempStorageArray, setUrlTempStorageArray] = useState([]);
     const [isLoading, setIsLoading] = useState(false);
  
  useEffect(() => {
    const headers = {
      "Content-Type": "application/json",
      Authorization: `Bearer ${localStorage?.getItem("jwt")}`,
    };
    noAuthAxios
      .get(
        `${apiEndPoint?.GET_COUNTRY_DETAILS}?requestId=${localStorage?.getItem(
          "id"
        )}`,
        {
          headers: headers,
        }
      )
      .then((response) => {
        setAllCountries(response?.data?.countries);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
console.log("urlTempStorageArray",countryCode?.length === 0)
  const handleSubmit = () => {
            if(countryCode?.length === 0 || urlTempStorageArray?.length === 0) return;
            // toastMessage("Generate successfully!");
            authAxios
              .put(`${apiEndPoint?.SET_COUNTRY_CODE_BY_THUMB_IMAGE}${countryCode}?requestId=qwds&thumbImageUrl=${urlTempStorageArray[0]?.url}`)
              .then((res) => {
                // setIsEdit(false);
              
                toastMessage("thumb image added successfully!");
                setIsLoading(false);
                window.location.reload()
              })
              .catch((err) => console.log(err));
     
  }
    return <div className="max-w-[650px] mx-auto">
        <h2 className="text-[22px] underline text-btn-secondary text-center mt-[20px]">Country Thumb Image Set</h2>
       <div className="flex flex-col gap-[20px] justify-start itmes-center pt-[20px]">
       <div className="mt-2 w-full">
                <div>Country</div>
                <SingleSelectParent
                  required={true}
                  selectedValue={countryCode}
                  setSelectedValue={setCountryCode}
                  data={allCountries}
                  placeholder="Country"
                  displayField={"country"}
                  selectedFieldName="countryCode"
                  // isAdmin={
                  //     process.env
                  //         .REACT_APP_USER_TYPE ===
                  //     UserType.ADMIN
                  //         ? true
                  //         : false
                  // }
                  isReadOnly={false}
                  // rejectionArray={
                  //     addressRejectArray
                  // }
                  // setRejectionArray={
                  //     setAddressRejectArray
                  // }
                  name="country"
                />
              </div>

              <div className="mx-[49px]">
              <div className="mt-4 mb-2">Upload Image:</div>
              {/* <RemoveableImageUploadViewCategory/> */}
              <div className="flex">
                <div className="mb-10  w-3/5">
                  <FileMultipleCategory
                    width={"w-full"}
                    id={1}
                    setNeedUpdate={() => {}}
                    urlTempStorageArray={urlTempStorageArray}
                    setUrlTempStorageArray={setUrlTempStorageArray}
                    multiple={false}
                    // setIsLoading={setIsLoading}
                  />
                </div>
                {isLoading ? (
                  <div
                    className={`flex items-center justify-center  w-2/5 ${
                      urlTempStorageArray?.length ? "h-[245px]" : ""
                    }`}
                  >
                    <Loading />
                  </div>
                ) : (
                  <div className="w-2/5 ml-[20px]">
                    <img
                      className={`rounded-lg w-full ${
                        urlTempStorageArray?.length || urlTempStorageArray[0]?.url ? "h-[250px]" : ""
                      }`}
                      src={urlTempStorageArray[0]?.url || ""}
                      alt="Ghuddy"
                    />
                    <div className="text-txt-primary text-center mt-[10px] text-[12px]">
                      Image File:{" "}
                      {urlTempStorageArray?.length > 0
                        ? urlTempStorageArray[urlTempStorageArray?.length - 1]
                            .fileName
                        : `${name ? name : "No"} image`}
                    </div>
                  </div>
                )}
              </div>
            </div>

            <button onClick={() => handleSubmit()} className="bg-btn-primary py-[10px] rounded-[12px] text-bg-white hover:bg-btn-secondary active:bg-btn-primary">Submit</button>
       </div>
    </div>
}