export const S3_BUCKET = "ghuddy-raw-dev";

export const Movement = {
  CREATE_OWNER: "/user/owner/",
  CREATE_CONTRACT: "contract/addinfo/",
  CREATE_HOTEL_PROPERTY: "admin/controller/rentalproperty/",
  CREATE_CATEGORY: "admin/controller/roomcategory/",
  SUBMIT_FOR_REVIEW: "contract/submit/",
  // SIGNUP: "open/firebase/signup/",
  // SIGNIN: "open/signin/",
  RENTAL_PROPERTY_IMAGE_UPLOAD: "admin/controller/rentalproperty/images/add",
  CATEGORY_IMAGE_UPLOAD: "admin/rentalproperty/category/images/add",
};

export const BlogMarchant = {
  POST_BLOG: "/content/3bbblog/blog/ghuddy",
  ALL_BLOGS: "/content/3bbblog/all/blog/ghuddy",
};

export const ReviewFor = {
  OWNER: "OWNER",
  CONTRACT: "CONTRACT",
  DOCUMENT: "DOCUMENT",
  HOTEL: "HOTEL",
  CATEGORY: "CATEGORY",
  SUBMIT: "SUBMIT",
};

export const UserType = {
  ADMIN: "ADMIN",
  USER: "USER",
};

export const SummeryStatus = {
  DRAFT: "DRAFT",
  PENDING: "PENDING",
  OPEN: "OPEN",
  EMPTY: "EMPTY",
  REJECTED: "REJECTED",
  ACCEPTED: "ACCEPTED",
};

export const PositionMove = {
  OWNER: "owner-move",
  CONTRACT: "contract-move",
  DOCUMENT: "document-move",
  HOTEL: "add-hotel-move",
  CATEGORY: "category-move",
  LANDING: "nothing",
};

export const NavbarSelection = {
  DASHBOARD_SELECT: "DASHBOARD_SELECT",
  BOOKING_SELECT: "BOOKING_SELECT",
  HOTEL_SELECT: "HOTEL_SELECT",
  CREW_SELECT: "CREW_SELECT",
  CATEGORY_SELECT: "CATEGORY_SELECT",
};

export const DashboardRoutes = {
  DASHBOARD: "/dashboard",
  FLOWCONTROL: "/flow",
  HOTELS: "/dashboard/RENTAL",
  HOTEL_DETAILS: "/dashboard/hotels/details/",
  CATEGORIES: "/dashboard/categories",
  CATEGORIES_DETAILS: "/dashboard/categories/details/",
  BOOKING: "/dashboard/booking",
  CREW: "/dashboard/crew",
  ALL_BOOKINGS: "/dashboard/all_books",
  POLICIES: "/dashboard/policies",
  MARCHENT_FETCH_SINGLE_HOTEL_TNC: "/dashboard/hotel_tnc/",
  TOURS: "/dashboard/TOUR",
  TOUR_BOOKINGS: "/dashboard/tour-bookings-review",
};

export const merchantRoute = {
  SIGNUP: "/signup",
  LOGIN: "/login",
  HOME: "/",
  LANDING: "/registration",
  OWNER: "/registration/owner",
  CONTRACT: "/registration/contract-info",
  DOCUMENT: "/registration/document",
  HOTELS: "/registration/hotels",
  HOTEL: "/registration/hotels/",
  CATEGORIES: "/registration/categories",
  CATEGORY: "/registration/categories/",
};

export const merchantExcursionRoute = {
  EXCURSION_DASHBOARD: "/dashboard/excursion",
  EXCURSION_CREATE: "/dashboard/excursion/create",
  EXCURSION_CREATE_OPTION: "/dashboard/excursion/create-option",
  EXCURSION_CREATE_MERCHANT_PRICE_TOOLS:
    "/dashboard/excursion/create-merchant/price-tools",
};

export const merchantTourRoute = {
  TOUR_DASHBOARD: "/dashboard/TOUR",
  CREATE_TOUR: "/dashboard/tours/create-package/",
  CREATE_PACKAGE_INIT: "/dashboard/tours/create-package-init/",
  CREATE_TOUR_PACKAGE_TYPE: "/dashboard/tours/create-package/package-type",
  CREATE_TOUR_FOOD_PACKAGE: "/dashboard/tours/create-package/food-package",
  CREATE_TOUR_ACCOMODATION_PACKAGE:
    "/dashboard/tours/create-package/accomodation-package",
  CREATE_TOUR_TRANSPORTATION_PACKAGE:
    "/dashboard/tours/create-package/transportation-package",
  CREATE_TOUR_TOUR_GUIDE: "/dashboard/tours/create-package/guide",
  CREATE_TOUR_TOUR_SPOT_FEE: "/dashboard/tours/create-package/spot-fee",

  ACTIVE_TOUR: "/dashboard/tours/active-tour",
  SUBSCRIBE_TOUR: "/dashboard/tours/subscribe-tour",
  SUBSCRIBE_TOUR_WITH_PACKAGE:
    "/dashboard/tours/subscribe-tour-without-package",
  TOURONE: "/dashboard/tours/tour-one/",
  PACKAGE_CREATE: "/dashboard/tours/package-create",
  MY_TOUR: "/dashboard/tours/my-tour/",
  PRICE_TOOL: "/dashboard/tours/pricetool/",
  ABIBILITY: "/dashboard/tours/abibility-init/",
  ABIBILITY_PACKAGE_ID: "/dashboard/tours/abibility/",
  ABIBILITY_PRICE_ABIBILITY: "/dashboard/tours/price_abibility/",
  MARCHANT_SET_ABIBILITY_V2: "/dashboard/abibility/",
  MARCHANT_VIEW_PACKAGE_LIST: "/dashboard/view/",
  MARCHANT_VIEW_PACKAGE_CREATE: "/dashboard/view/package/create/",

  MERCHANT_TOUR_AVAILABILITY: "/dashboard/tour-availability/",
};

export const adminRoute = {
  ADMIN_LANDING: "/admin",
  ADMIN_ALL_CONTRACTS: "/admin/contracts/all",
  ADMIN_SUBMITTED_CONTRACTS: "/admin/contracts/submitted",
  ADMIN_PAYMENTS: "/admin/payments",
  ADMIN_CALCULATOR: "/admin/price-calculator",
  ADMIN_FAQ: "/admin/faq",
  ADMIN_FAQ_GROUP_ID: "/admin/faq/group-id/",
  ADMIN_OWNER: "/admin/owner",
  ADMIN_OWNERV2: "/admin/ownerv2",
  ADMIN_REVIEW_HOTEL: "/admin/review/hotel",
  ADMIN_INSPIRED_LOCATION: "/admin/inspired-location",
  ADMIN_ADD_LOCATION: "/admin/inspired-location/add",
  ADMIN_REVIEW_CATEGORY: "/admin/review/category",
  ADMIN_ICON_MANAGE: "/admin/icons-images",
  ADMIN_BLOG_POST_CREATE: "/admin/create-blog",
  ADMIN_BLOG_POST_CREATE_TAGS: "/admin/create-blog-tags",
  ADMIN_COUPON_MANAGE: "/admin/coupon-manage",
  ADMIN_COUPON_CREATE: "/admin/coupon-manage/create",
  ADMIN_BOOKINGS: "/admin/bookings",
  ADMIN_BOOKINGSNEW: "/admin/bookings/new",
  ADMIN_SALESORDER: "/admin/bookings/salesorder",
  ADMIN_REJECTIONS_BOOKING: "/admin/booking/review",
  ADMIN_REJECTIONS_TOUR_BOOKING: "/admin/booking/tours-review",
  ADMIN_SALES_SEARCH_ADD_SEE_DETAILS: "/admin/booking/search-see-all-details",
  ADMIN_TOURS_BOOKING: "/admin/tour-bookings",

  ADMIN_SYNC_HOTEL: "/admin/sync-hotel",
  ADMIN_CREATE_USER: "/admin/users/create",
  ADMIN_DIRECT_BOOKINGS: "/admin/direct-bookings",
  ADMIN_REJECTIONS_BOOKINGS: "/admin/bookings/rejections",
  ADMIN_HOTEL_LIST: "/admin/hotels",
  ADMIN_HOTEL_DETAILS: "/admin/hotel-details",
  ADMIN_DEAL_TOOL: "/admin/deals-tool",
  ADMIN_ALL_INVOICE: "/admin/all-invoice",
  ADMIN_INVOICE_TOOL: "/admin/invoice-tool",

  ADMIN_CREATE_INVOICE: "/admin/create-invoice",
  ADMIN_ALL_INVOICE_RECORDS: "/admin/all-invoice-records",
  ADMIN_SINGLE_INVOICE_DETAILS: "/admin/invoice-details/",
  ADMIN_CREATE_PAYMENT_SLIP: "/admin/create-payment-slip/",
  ADMIN_CREATE_PAYMENT_METHOD: "/admin/create-payment-method",
  ADMIN_VERIFY_PAYMENTS: "/admin/verify-payments",

  ADMIN_SET_HOTEL_PRICE: "/admin/set-hotel-price",

  ADMIN_CANCELLATION_BOOKINGS: "/admin/bookings/cancellations",
  ADMIN_CONTRACT: "/admin/contarct-info",
  ADMIN_DOCUMENT: "/admin/document",
  ADMIN_HOTELS: "/admin/review/hotels",
  ADMIN_HOTEL: "/admin/review/hotel/",
  ADMIN_CATEGORIES: "/admin/categories",
  ADMIN_CATEGORY: "/admin/category/",
  ADMIN_TERMS_CONDITIONS: "/admin/terms-and-conditions/",
  ADMIN_TERMS_CONDITIONS_CREATE: "/admin/terms-and-conditions/create",
  ADMIN_NON_EXPRESS_BOOKINGS: "/admin/booking/non-express",

  ADMIN_ROLES: "/admin/roles",
  ADMIN_PERMISSIONS: "/admin/permissions",
  ADMIN_PERMISSIONS_ASSIGN: "/admin/permissions/assign",
  ADMIN_FACILITY_MANAGE: "/admin/facility/manage",
  ADMIN_TOURS: "/admin/all-tours",
  ADMIN_TOUR_CREATE_TOOL: "/admin/tour-create",
  ADMIN_TOUR_ADDED_TOOL: "/admin/tour-added",
  ADMIN_TOUR_ACTIVITY_TYPE_TOOL: "/admin/tour-activity-type",
  ADMIN_TOUR_ACTIVITY_ADD_TOOL: "/admin/tour-activity-add",
  ADMIN_TOUR_TRANSPORT_ROUTE_TOOL: "/admin/tour-transport-route",
  ADMIN_TOUR_TRANSPORT_PROVIDER_TOOL: "/admin/tour-transport-provider",
  ADMIN_TOUR_TRANSPORT_MODE_TOOL: "/admin/tour-transport-mode",
  ADMIN_TOUR_TRANSPORT_BRANDS_TOOL: "/admin/tour-transport-brands",
  ADMIN_TOUR_CREATED_TOOL: "/admin/tour-created",
  ADMIN_TOUR_VIEW_TOOL: "/admin/tour-view",
  ADMIN_TOUR_PACKAGE_CREATE_TOOL: "/admin/tour-package-create",

  ADMIN_TOUR_ACCOMANDATION_TYPE_TOOL: "/admin/tour-accomandation-type",
  ADMIN_TOUR_ACCOMANDATION_ADD_TOOL: "/admin/tour-accomandation-add",
  ADMIN_TOUR_ROOM_CATEGORY_TOOL: "/admin/tour-room-category",
  ADMIN_TOUR_ROOM_TYPE_TOOL: "/admin/tour-room-type",
  ADMIN_TOUR_CREATED_FOOD: "/admin/food-add",
  ADMIN_TOUR_CREATED_FOOD_NAME: "/admin/foodName",
  ADMIN_TOUR_CREATED_MEAL_PACKAGE: "/admin/meal-package",
  ADMIN_TNC_TOOL: "/admin/tnc-create",
  ADMIN_TNC_TOOL_PRODUCT: "/admin/add/product/tnc-create",
  ADMIN_BRING_LIVE_TOOL: "/admin/live-hotel",
  ADMIN_TOUR_MANAGE: "/admin/tour_management",
  ADMIN_TOUR_CREATE: "/admin/tour_management/create",
  ADMIN_COUNTRY_THUMB_IMAGE_ADD: "/admin/country-thumb-set",
  ADMIN_AIRPORT_DESTINATION_ADD: "/admin/airport-destination-set",
  ADMIN_TOUR_Details_BASE: "/admin/tour_management/details",
  ADMIN_TOUR_Details: "/admin/tour_management/details/:id",
  ADMIN_TOUR_ADD_CATEGORY: "/admin/tour_management/:id/add-category",
  ADMIN_TOUR_EDIT_CATEGORY: "/admin/tour_management/package/:catId/edit",
  ADMIN_PROPERTY_FACILITY_TOOL: "/admin/property-facility",
  ADMIN_DISPLAY_GROUP_TOOL: "/admin/display-group",
  ADMIN_FACILITY_TYPE: "/admin/facility-type",
  ADMIN_UI_FILTER: "/admin/ui-filter",
  ADMIN_ROOM_CATEGORY_FILTER: "/admin/room-category-filter",
  ADMIN_ROOM_CATEGORY_TYPE: "/admin/room-category-type",

  ADMIN_AIRLINE_CREATE_TOOL: "/admin/airports-create-tool",
  ADMIN_AIRLINE_LOGO_CREATE_TOOL: "/admin/airline-logo-tool",
  ADMIN_AIRLINE_TICKET_STATUS: "/admin/fare-ticket-status",
  ADMIN_OTP_QUERY: "/admin/otp-query",
  ADMIN_THUMB_IMAGE: "/admin/hotel_data_management/thumbImage",
  ADMIN_SET_RATINGS: "/admin/hotel_data_management/set-ratings",
  ADMIN_SET_REVIEW: "/admin/hotel_data_management/set-review",
  ADMIN_THUMB_IMAGE_DETAILS: "/admin/hotel_data_management/thumbImage/:id",
  ADMIN_FETCH_ALL_HOTEL_FACILITY: "/admin/hotel_facility",
  ADMIN_FETCH_ALL_HOTEL_TNC: "/admin/hotel_tnc",
  ADMIN_FETCH_ALL_HOTEL_TOP: "/admin/set-top-hotel",
  ADMIN_FETCH_ALL_HOTEL_FACILITY_DETAILS: "/admin/hotel_facility/:id",
  ADMIN_FETCH_SINGLE_HOTEL_TNC: "/admin/hotel_tnc/:id",

  ADMIN_SET_HOTEL_SPECIALITY: "/admin/hotel_speciality",
  ADMIN_SET_HOTEL_SPECIALITY_DETAILS: "/admin/hotel_speciality/:id",

  ADMIN_ACT_FACILITY_GROUP: "/admin/activity/facility-group",
  ADMIN_ACT_FACILITY: "/admin/activity/facility",

  ADMIN_EXCURSION_GROUP_ONE: "/admin/excursion/group-one",
  ADMIN_EXCURSION_GROUP_TWO: "/admin/excursion/group-two",
  ADMIN_EXCURSION_GROUP_THREE: "/admin/excursion/group-three",

  ADMIN_EXCURSION_COMPONENT: "/admin/excursion/component",
  ADMIN_EXCURSION_SPECIALITY: "/admin/excursion/speciality",

  ADMIN_EXCURSION_PACKAGE_VARIABLE: "/admin/update/excursion/package/variable",
};

export const UpdateTypeCategory = {
  DEFAULT: "DEFAULT",
  DATA: "DATA",
  IMAGE: "IMAGE",
};
