
import React, { useEffect, useState } from "react";
import Modal from "react-modal";
import { useDispatch } from "react-redux";
import DatePicker from "react-datepicker";
import { apiEndPoint } from "../../contants/Endpoints";
import { categoryAvailabilityPostApi } from "../../store/marchantDashboard/categoryAvailability/api/categoryAvailabilityApi";
import { authAxios } from "../../utils/axiosWrapper";
import CustomValidation, {
  customValidationSingle,
} from "../../utils/customValidation";
import { DateFormatTranferToSixDigit } from "../../utils/DateFormatTranferToSixDigit";
import { toastMessage } from "../../utils/toast";
import ButtonFilled from "../ButtonFilled";
import InputComponent from "../InputComponent";
import SingleSelectParentCategoryReturn from "../singleSelect/singleSelectHotelReturn/SingleSelectParentCategoryReturn";

const customStyles = {
  content: {
    // top: "50%",
    // left: "50%",
    // right: "auto",
    // bottom: "auto",
    // marginRight: "-50%",
    // transform: "translate(-50%, -50%)",
    height: "90%",
    maxWidth: "96%",
    borderRadius: "32px",
    backgroundColor: "#FFFBEF",
    zIndex: 100,
    width: "90%",
  },
};

// Make sure to bind modal to your appElement (https://reactcommunity.org/react-modal/accessibility/)
Modal.setAppElement("#root");

const AvailabilityModal = ({
  hotelToShow,
  openDefault,
  setOpenDefault,
  hotelList,
}) => {
  const [modalIsOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();

  const [selectedCategory, setSelectedCategory] = React.useState([]);
  const [categories, setCategories] = React.useState([]);
  const [hotelListState, setHotelListState] = React.useState(hotelList);
  const [hotelShow, setHotelShow] = React.useState(null);

  useEffect(() => {
    if (hotelShow) {
      authAxios
        .get(
          `${apiEndPoint.ADMIN_GET_CATEGORY_BY_PROPERTY}${hotelShow.id}?requestId=2`
        )
        .then((res) => {
          setCategories(res.data.roomCategoryList);
          setSelectedCategory(res.data.roomCategoryList[0]);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [hotelShow]);

  useEffect(() => {
    setHotelListState(hotelList);
    if (hotelList?.length) {
      if (hotelToShow) {
        const index = hotelList.findIndex((el) => el.id === hotelToShow.id);
        setHotelShow(hotelList[index]);
      } else {
        setHotelShow(hotelList[0]);
      }
    }
  }, [hotelList]);

  // useEffect(() => {
  //   // setCategories(allCategoriesMerchantReducer);
  // }, [allCategoriesMerchantReducer]);

  useEffect(() => {
    if (openDefault === true) {
      setIsOpen(true);
    }
  }, [openDefault]);

  function closeModal() {
    setIsOpen(false);
    setOpenDefault(false);
  }
  // console.log("navigatepass:", navigatepass);

  // state for category availability
  const [discount, setDiscount] = useState(0);
  const [totalRoom, setTotalRoom] = useState(0);
  const [baseRate, setBaseRate] = useState(0);
  // const [ghuddyCommission, setGhuddyCommission] = useState(15);
  const [priceToCustomer, setPriceToCustomer] = useState();
  const [availableRoom, setAvailableRoom] = useState();
  const [vat, setVat] = useState(15);

  // const [val, setVal] = useState();
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);

  // const [currency, setCurrency] = useState("BDT");
  // const [priceWithVat, setPriceWithVat] = useState(0);
  // const [priceWithoutVat, setPriceWithoutVat] = useState(0);
  // const [vatPercent, setVatPercent] = useState(15);

 
  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };
  const valChange = (e) => {
    // setVal(e.value);
    setTimeout(() => {
      customValidationSingle("date");
    }, 200);
  };
  // useEffect(() => {
  //   if (val) {
  //     setStartDate(val[0]);
  //     setEndDate(val[1]);
  //   }
  // }, [val]);

  useEffect(() => {
    console.log("changing");
    if (selectedCategory) {
      setBaseRate(selectedCategory?.baseRate || 0);
      setTotalRoom(selectedCategory?.noOfRoomsInCategory || 0);
      setVat(selectedCategory?.vatPercent || 15);
      setPriceToCustomer(selectedCategory?.baseRate);
      setDiscount(0);
    }
  }, [selectedCategory]);

  let categoryAvailability_obj = {
    availableRoom: parseInt(availableRoom),
    baseRate: parseInt(baseRate),
    currency: selectedCategory?.currency || "BDT",
    deactivatedRoom: parseInt(totalRoom) - parseInt(availableRoom),
    discount: parseFloat(discount),
    discountUnit: "FLAT",
    // ghuddyCommission: parseInt(ghuddyCommission),
    endDate: parseInt(DateFormatTranferToSixDigit(endDate)),
    priceToCustomer: parseInt(priceToCustomer),
    totalRoom: parseInt(totalRoom),
    meta: {
      categoryName: selectedCategory?.categoryName,
      vat: parseInt(vat),
    },
    requestId: localStorage.getItem("id"),
    startDate: parseInt(DateFormatTranferToSixDigit(startDate)),
    vatPercent: vat,
  };

  const buttonFilledHandle = () => {
    if (CustomValidation()) {
      if (selectedCategory?.roomCategoryId) {
        if (availableRoom <= totalRoom) {
          dispatch(
            categoryAvailabilityPostApi(
              selectedCategory?.roomCategoryId,
              categoryAvailability_obj,
              closeModal
            )
          );
        } else {
          toastMessage(
            "Available room cannot be greater than total room",
            "error"
          );
        }
      } else {
        toastMessage("Please select a category", "error");
      }
    }
  };

  return (
    <div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={customStyles}
        contentLabel="Example Modal"
      >
        <div className="bg-bg-primary min-h-full relative flex items-center justify-center">
          <div className="flex justify-end p-4 absolute top-0 w-full">
            <button onClick={closeModal}>
              <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.4109 8.99928L18.701 1.70928C18.8648 1.51798 18.9504 1.27191 18.9407 1.02023C18.9309 0.768555 18.8266 0.529816 18.6485 0.351722C18.4704 0.173627 18.2317 0.0692943 17.98 0.0595732C17.7283 0.049852 17.4823 0.135459 17.291 0.299285L10.0009 7.58928L2.71095 0.289285C2.51965 0.125458 2.27357 0.0398528 2.0219 0.0495739C1.77022 0.0592951 1.53148 0.163627 1.35339 0.341722C1.17529 0.519816 1.07096 0.758555 1.06124 1.01023C1.05152 1.26191 1.13712 1.50798 1.30095 1.69928L8.59095 8.99928L1.29095 16.2893C1.18627 16.3789 1.10125 16.4893 1.04122 16.6133C0.981202 16.7374 0.947471 16.8725 0.942151 17.0102C0.936832 17.1479 0.960038 17.2853 1.01031 17.4136C1.06059 17.5419 1.13685 17.6585 1.2343 17.7559C1.33175 17.8534 1.4483 17.9296 1.57663 17.9799C1.70495 18.0302 1.84228 18.0534 1.98 18.0481C2.11772 18.0428 2.25286 18.009 2.37692 17.949C2.50098 17.889 2.6113 17.804 2.70095 17.6993L10.0009 10.4093L17.291 17.6993C17.4823 17.8631 17.7283 17.9487 17.98 17.939C18.2317 17.9293 18.4704 17.8249 18.6485 17.6468C18.8266 17.4688 18.9309 17.23 18.9407 16.9783C18.9504 16.7267 18.8648 16.4806 18.701 16.2893L11.4109 8.99928Z"
                  fill="#2A220E"
                  fillOpacity="0.45"
                />
              </svg>
            </button>
          </div>
          <div className="w-full grid grid-cols-6 md:px-8 py-10">
            <div className="2xl:col-span-2 col-span-10 md:px-10 flex items-center">
              <div>
                {/* <Datepicker
                  controls={["calendar"]}
                  select="range"
                  calendarType="month"
                  pages={2}
                  value={val}
                  onChange={valChange}
                  display="inline"
                  touchUi={true}
                  responsive={true}
                  min={new Date()}
                /> */}
                 <DatePicker
      swapRange
      selected={startDate}
      onChange={onChange}
      startDate={startDate}
      endDate={endDate}
      // excludeDates={[addDays(new Date(), 1), addDays(new Date(), 5)]}
      selectsRange
      selectsDisabledDaysInRange
      inline
    />
              </div>
            </div>
            <div className="2xl:col-span-4 col-span-10 2xl:col-start-3 md:px-16 flex flex-col gap-y-4">
              <div className="">
                <SingleSelectParentCategoryReturn
                  warningView={false}
                  selectedValue={hotelShow}
                  setSelectedValue={setHotelShow}
                  data={hotelListState}
                  placeholder="Hotel Name"
                  displayField={"propertyName"}
                  selectedFieldName="id"
                />
              </div>
              <div className="">
                <SingleSelectParentCategoryReturn
                  warningView={false}
                  selectedValue={selectedCategory}
                  setSelectedValue={setSelectedCategory}
                  data={categories}
                  placeholder="Category Name"
                  displayField={"categoryName"}
                  selectedFieldName="roomCategoryId"
                />
              </div>
              <InputComponent
                warningView={false}
                required={true}
                inputValue={
                  endDate != undefined && startDate != undefined
                    ? `${new Date(startDate).toDateString()} - ${new Date(
                        endDate
                      ).toDateString()}`
                    : ""
                }
                // setInputValue={setBaseRate}
                placeholder="Dates"
                isReadOnly={true}
                name="date"
              />
              <div className="grid md:grid-cols-2 gap-4">
                <InputComponent
                  warningView={false}
                  required={true}
                  number={true}
                  inputValue={baseRate}
                  setInputValue={setBaseRate}
                  placeholder={`Corporate Rate`}
                  addSpan={true}
                  isReadOnly={true}
                  spanValue={selectedCategory?.currency || "BDT"}
                  name="baseRate"
                />
                <InputComponent
                  warningView={false}
                  required={true}
                  number={true}
                  inputValue={priceToCustomer}
                  setInputValue={(e) => {
                    const diff = baseRate - parseFloat(e);
                    if (diff >= 0) {
                      setDiscount(100 * (diff / baseRate));
                    }
                    setPriceToCustomer(e);
                  }}
                  placeholder="Customer Price"
                  name="priceToCustomer"
                  addSpan={true}
                  spanValue={selectedCategory?.currency || "BDT"}
                />
              </div>
              <div className="grid md:grid-cols-2 gap-4">
                <InputComponent
                  warningView={false}
                  required={true}
                  inputValue={discount}
                  setInputValue={(e) => {
                    if (parseFloat(e) <= 100) {
                      setPriceToCustomer(
                        baseRate - baseRate * (parseFloat(e) / 100)
                      );
                    }
                    setDiscount(e);
                  }}
                  placeholder="Discount"
                  addSpan={true}
                  spanValue={"%"}
                  name="discount"
                />
              </div>

              <div className="grid md:grid-cols-2 gap-4">
                <InputComponent
                  warningView={false}
                  required={true}
                  inputValue={totalRoom}
                  setInputValue={setTotalRoom}
                  isReadOnly={true}
                  placeholder="Total Room"
                  name="totalRoom"
                />
                <InputComponent
                  warningView={false}
                  required={true}
                  inputValue={availableRoom}
                  setInputValue={setAvailableRoom}
                  // setInputValue={(value)=>parseInt(value) <= totalRoom && setAvailableRoom}
                  placeholder="Available Rooms"
                  number={true}
                  name="availableRoom"
                />
              </div>
              <div className="flex justify-center mt-6">
                <div className="w-1/2">
                  <ButtonFilled
                    title="Submit"
                    w="100%"
                    h="49px"
                    buttonFilledHandle={buttonFilledHandle}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AvailabilityModal;
