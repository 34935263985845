
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import ReactQuill from "react-quill";
import ButtonFilled from "../../../components/ButtonFilled";
import Checkbox from "../../../components/Checkbox";
import FileMultipleCategory from "../../../components/multipleimage/FileMultipleCategory";
import { apiEndPoint } from "../../../contants/Endpoints";
import { authAxios } from "../../../utils/axiosWrapper";
import { DateFormatTranferToSixDigit } from "../../../utils/DateFormatTranferToSixDigit";
import { toastMessage } from "../../../utils/toast";

const modules = {
    toolbar: [
        [{ header: [1, 2, false] }],
        ["bold", "italic", "underline", "strike", "blockquote"],
        [
            { list: "ordered" },
            { list: "bullet" },
            { indent: "-1" },
            { indent: "+1" },
        ],
        ["link", "image"],
        ["clean"],
    ],
};

function AdminTourCreate() {
    const [title, setTitle] = useState("");
    const [req, setReq] = useState("");
    const [amenty, setAmnety] = useState("");
    const [description, setDescription] = useState("");
    const [tag, setTag] = useState("");
    const [location, setLocation] = useState("");
    const [cancellation, setCancellation] = useState("");
    const [bannerImage, setBannerImage] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [amneties, setAmneties] = useState([]);
    const [requirements, setReqs] = useState([]);
    const [imageUrls, setImageUrls] = useState([]);
    const [active, setActive] = useState(true);
    const [isLoading, setIsLoading] = useState(false);
    const [urlTempStorageArray, setUrlTempStorageArray] = useState([]);

    function createMarkup() {
        return { __html: description };
    }

    useEffect(() => {
        if (urlTempStorageArray.length > 0) {
            setBannerImage(urlTempStorageArray.pop());
        }
    }, [urlTempStorageArray]);

    const submitData = () => {
        if (!bannerImage) {
            toastMessage("Please add banner image!");
            return false;
        }
        if (imageUrls.length < 2) {
            toastMessage("Minimum two additional image required!");
            return false;
        }
        setIsLoading(true);
        try {
            const data = {
                amenities: amneties,
                bannerImage: bannerImage.url,
                cancellationPolicy: cancellation,
                description: description,
                endDate: DateFormatTranferToSixDigit(endDate),
                imageUrls: imageUrls.map((el) => el.url),
                isActive: active,
                location: location,
                requestId: "string",
                startDate: DateFormatTranferToSixDigit(startDate),
                tag: tag,
                title: title,
                requirements,
            };
            authAxios
                .post(`${apiEndPoint.ADMIN_CREATE_TOUR}`, data)
                .then((res) => {
                    toastMessage("Created Successfully");
                    setIsLoading(false);
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                })
                .catch((err) => {
                    setIsLoading(false);
                    toastMessage("Operation Failed");
                });
        } catch (error) {
            setIsLoading(false);
            toastMessage("Operation Failed");
        }
    };

    const removeBannerImage = () => {
        setUrlTempStorageArray([]);
        setBannerImage(null);
    };

    const removeOnePhoto = (url) => {
        setImageUrls(imageUrls.filter((el) => el.url !== url));
    };

    return (
        <div className="container mx-auto">
            <div className="flex justify-between items-center mb-2">
                <div className="text-lg ">Create New Tour</div>
                <ButtonFilled
                    title={"Submit"}
                    buttonFilledHandle={submitData}
                    w="200px"
                    disable={isLoading ? "yes" : "no"}
                />
            </div>
            <hr className="mb-4" />
            <div className="flex gap-4 items-start">
                <div className="flex flex-col gap-y-4 w-1/2 h-[80vh] overflow-y-scroll">
                    <div>
                        <div className="mb-1"> Tour Title </div>
                        <input
                            type={"text"}
                            value={title}
                            onChange={(e) => {
                                setTitle(e.target.value);
                            }}
                            className="bg-[transparent] border border-[#e2e2e2] rounded-xl h-[49px] px-4 text-[#1b1b1b] w-full"
                        />
                    </div>
                    <div className="ml-1">
                        <Checkbox
                            title={"Is Active?"}
                            checked={active}
                            handleChange={() => setActive(!active)}
                        />
                    </div>
                    <div>
                        <div className="mb-1"> Tour description </div>
                        <ReactQuill
                            theme="snow"
                            defaultValue={""}
                            value={description}
                            onChange={setDescription}
                            placeholder={"Write something..."}
                            modules={modules}
                        />
                    </div>
                    <div className="flex gap-2 w-full">
                        <div className="w-full">
                            <div className="mb-1"> Tour Tag </div>
                            <input
                                type={"text"}
                                value={tag}
                                onChange={(e) => {
                                    setTag(e.target.value);
                                }}
                                className="bg-[transparent] border border-[#e2e2e2] rounded-xl h-[49px] px-4 text-[#1b1b1b] w-full"
                            />
                        </div>
                        <div className="w-full">
                            <div className="mb-1"> Tour Location </div>
                            <input
                                type={"text"}
                                value={location}
                                onChange={(e) => {
                                    setLocation(e.target.value);
                                }}
                                className="bg-[transparent] border border-[#e2e2e2] rounded-xl h-[49px] px-4 text-[#1b1b1b] w-full"
                            />
                        </div>
                    </div>

                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (amenty.length > 0) {
                                amneties.push(amenty);
                                setAmnety("");
                            }
                        }}
                    >
                        <div className="mb-1"> Add amneties </div>
                        <input
                            type={"text"}
                            value={amenty}
                            onChange={(e) => {
                                setAmnety(e.target.value);
                            }}
                            className="bg-[transparent] border border-[#e2e2e2] rounded-xl h-[49px] px-4 text-[#1b1b1b] w-full"
                        />
                        <button type="submit"></button>
                    </form>
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                            if (req.length > 0) {
                                requirements.push(req);
                                setReq("");
                            }
                        }}
                    >
                        <div className="mb-1"> Add requirements </div>
                        <input
                            type={"text"}
                            value={req}
                            onChange={(e) => {
                                setReq(e.target.value);
                            }}
                            className="bg-[transparent] border border-[#e2e2e2] rounded-xl h-[49px] px-4 text-[#1b1b1b] w-full"
                        />
                        <button type="submit"></button>
                    </form>

                    <div>
                        <div className="mb-1"> Cancellation </div>
                        <input
                            type={"text"}
                            value={cancellation}
                            onChange={(e) => {
                                setCancellation(e.target.value);
                            }}
                            className="bg-[transparent] border border-[#e2e2e2] rounded-xl h-[49px] px-4 text-[#1b1b1b] w-full"
                        />
                    </div>

                    <div className="text-[#1b1b1b]">
                        <div className="mb-2">Banner image</div>
                        <div className="mb-4 text-center">
                            File:
                            <span className="text-sm ml-2 italic text-[#e2e2e2]">
                                {bannerImage ? (
                                    <div>
                                        {bannerImage.filename}
                                        <button>X</button>
                                    </div>
                                ) : (
                                    "No banner iamge"
                                )}
                            </span>
                        </div>
                        <FileMultipleCategory
                            id={1}
                            setNeedUpdate={() => {}}
                            urlTempStorageArray={urlTempStorageArray}
                            setUrlTempStorageArray={setUrlTempStorageArray}
                            multiple={false}
                            type={"default"}
                            upload={true}
                        />
                    </div>
                    <div className="text-[#1b1b1b]">
                        <div className="mb-2">Additional images</div>
                        <FileMultipleCategory
                            id={1}
                            setNeedUpdate={() => {}}
                            urlTempStorageArray={imageUrls}
                            setUrlTempStorageArray={setImageUrls}
                            multiple={true}
                            type={"default"}
                            upload={true}
                        />
                    </div>
                </div>

                <div className="border-l border-[#e1e1e1] pl-5 w-1/2 h-[80vh] overflow-y-scroll">
                    <div className="rounded-xl border-dashed border w-full border-[#e1e1e1] h-[220px] overflow-hidden relative">
                        {bannerImage && (
                            <img
                                src={bannerImage.url}
                                alt="Banner"
                                className="object-cover w-full"
                            />
                        )}
                        <button
                            onClick={() => {
                                removeBannerImage();
                            }}
                            disabled={!bannerImage}
                            className="disabled:text-[gray] disabled:bg-[#0000002c] disabled:no-underline absolute bottom-0 right-0 text-sm underline text-bg-primary hover:text-[blue] px-1 bg-[#0008]"
                        >
                            Remove
                        </button>
                    </div>
                    <div className="flex gap-2 flex-wrap mt-2">
                        {imageUrls.map((image) => (
                            <div className="rounded-lg border shadow overflow-hidden w-[220px] h-[120px] relative">
                                <img
                                    src={image.url}
                                    alt="Banner"
                                    className="object-cover w-full"
                                />
                                <button
                                    onClick={() => {
                                        removeOnePhoto(image.url);
                                    }}
                                    className="absolute bottom-0 right-0 text-sm underline text-bg-primary hover:text-[blue] px-1 bg-[#0008]"
                                >
                                    Remove
                                </button>
                            </div>
                        ))}
                    </div>
                    <div className="text-4xl font-bold my-4 text-[#1b1b1b]">
                        {title || "Tour Title"}
                    </div>
                    <div className="flex justify-start gap-4 my-2">
                        <div className="bg-btn-primary px-4 py-1 text-bg-white rounded-xl">
                            {tag || "Tour tag"}
                        </div>
                        <div className="bg-btn-secondary px-4 py-1 text-bg-white rounded-xl">
                            <i className="fa fa-location-dot mr-1"></i>
                            {location || "Tour location"}
                        </div>
                        <div style={{ flex: "1 1 auto" }}></div>
                        <div className="text-[#838383]">
                            <span>
                                <i className="fa fa-clock"></i>
                            </span>
                            {new Date(startDate).toDateString()} -
                            {new Date(endDate).toDateString()}
                        </div>
                    </div>
                    <div className="flex flex-wrap gap-4 my-4">
                        {amneties.map((item) => (
                            <div className="flex items-center shadow-lg pl-3  cursor-pointer rounded group/item">
                                <div className="py-1 pr-2">{item}</div>
                                <button
                                    onClick={() => {
                                        setAmneties([
                                            ...amneties.filter(
                                                (el) => el !== item
                                            ),
                                        ]);
                                    }}
                                    className="hover:bg-bg-white hidden bg-bg-primary py-1 px-2 group/edit group-hover/item:block"
                                >
                                    X
                                </button>
                            </div>
                        ))}
                    </div>
                    <div className="font-semibold">Requirements:</div>
                    <ul className="pl-2">
                        {requirements.map((item) => (
                            <li>
                                {item}
                                <button
                                    onClick={() => {
                                        setReqs([
                                            ...requirements.filter(
                                                (el) => el !== item
                                            ),
                                        ]);
                                    }}
                                    className="hover:bg-bg-white bg-bg-primary py-1 px-2 "
                                >
                                    X
                                </button>
                            </li>
                        ))}
                    </ul>
                    <div className="mt-4 font-semibold">Description</div>
                    <div
                        className="mt-2"
                        dangerouslySetInnerHTML={createMarkup()}
                    ></div>
                </div>
            </div>
        </div>
    );
}

export default AdminTourCreate;
